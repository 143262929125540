import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const ListArticleSkeleton: React.FC<any> = () => {
  return (
    <div className="blog-entry d-flex blog-entry-search-item">
      <div className="img-link me-4">
        <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
      </div>
      <div style={{width: '100%'}}>
        <span className="date"><Skeleton width={150} height={15} baseColor='#f2f3f4' highlightColor='#dadcde' /></span>
        <h2><Skeleton width={'100%'} height={20} baseColor='#f2f3f4' highlightColor='#dadcde' /></h2>
        <Skeleton width={'100%'} height={15} baseColor='#f2f3f4' highlightColor='#dadcde' count={3} />
        <Skeleton width={130} height={40} baseColor='#f2f3f4' highlightColor='#dadcde' />
      </div>
    </div>
  )
}

export default ListArticleSkeleton
