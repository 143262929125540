/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Logo from '../../assets/images/IQRA_Web.png'
import { getCategories } from '../../services/categories'
import { useParams, useHistory } from 'react-router-dom';
import { createMobileMenu } from '../../utils/Navbar';
import { initiateAOS } from '../../utils/Global';
import Skeleton from 'react-loading-skeleton';

const Header: React.FC<any> = () => {
  const cloneNavRef = useRef<any>()
  const siteMobileMenuBodyRef = useRef<any>()
  const history = useHistory()

  const { slug } = useParams<any>();
  const [categories, setCategories] = useState<any[]>()
  const [term, setTerm] = useState('')

  const handleSubmit = useCallback(() => {
    history.push(`/search?q=${term}`)
  }, [history, term]);

  useEffect(() => {
    const initiate = async () => {
      const data = await getCategories()
      setCategories(data)

      createMobileMenu(cloneNavRef, siteMobileMenuBodyRef)
      initiateAOS()
    }

    initiate()

    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleSubmit])

  return (
    <>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close">
            <span className="icofont-close js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body" ref={siteMobileMenuBodyRef}></div>
      </div>

      <nav className="site-nav">
        <div className="container">
          <div className="menu-bg-wrap">
            <div className="site-navigation">
              <div className="row g-0 align-items-center">
                <div className="col-3">
                  <a href="/" className="logo m-0 float-start"><img src={Logo} width={80} alt="IQRA Studio" /></a>
                </div>
                <div className="col-7 text-center">
                  <form onSubmit={handleSubmit} className="search-form d-inline-block d-lg-none">
                    <input type="text" className="form-control" placeholder="Search..." value={term} onChange={(e) => setTerm(e.target.value)} />
                    <span className="bi-search"></span>
                  </form>

                  <ul ref={cloneNavRef} className="js-clone-nav d-none d-lg-inline-block text-start site-menu mx-auto">
                    <li className={slug === undefined ? 'active' : ''}><a href="/">Home</a></li>
                    {categories ? categories.map((item, index) => (
                      <li key={index} className={`${slug === item.slug ? 'active' : ''} ${item.children?.length > 0 ? 'has-children' : ''}`}>
                        <a href={`/category/${item.slug}`}>{item.name}</a>
                        {item.children?.length > 0 && (
                          <ul className="dropdown">
                            {item.children.map((item: any) => <li key={item.id}><a href={`/category/${item.slug}`}>{item.name}</a></li>)}
                          </ul>
                        )}
                      </li>
                    )) : (
                      <>
                        <li>
                          <div style={{width: 150}}>
                            <Skeleton width={'100%'} baseColor='#1C6758' height={15} />
                          </div>
                        </li>
                        <li>
                          <div style={{width: 120}}>
                            <Skeleton width={'100%'} baseColor='#1C6758' height={15} />
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="col-2 text-end">
                  <div className="burger ms-auto float-end site-menu-toggle js-menu-toggle d-inline-block d-lg-none light">
                    <span></span>
                  </div>
                  <form onSubmit={handleSubmit} className="search-form d-none d-lg-inline-block">
                    <input type="text" className="form-control" placeholder="Search..." value={term} onChange={(e) => setTerm(e.target.value)} />
                    <span className="bi-search"></span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
