import React, { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import moment from 'moment'
import {Helmet} from 'react-helmet';
import { useParams } from 'react-router-dom';
import { capitalCase } from '../../utils/Global';
import { getCategoryBySlug } from '../../services/categories';

const PostByCategory: React.FC<any> = () => {
  let { slug } = useParams<any>();
  const [articles, setArticles] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const category = await getCategoryBySlug(slug)
      const response = await paginatePosts(category.id, 1, 10)
      setArticles(response?.data)
    }

    initiate()
  }, [slug])

  return (
    <section className="section">
      {articles && (
        <Helmet>
          <title>{capitalCase(slug.replace('-', ' '))}</title>
          <meta name="description" content={capitalCase(slug.replace('-', ' '))} />
          <meta name="keywords" content={Array.prototype.map.call(articles, function(item) { return item.title.rendered; }).join(",")} />
        </Helmet>
      )}

      <div className="container">
        <div className="row mb-4">
          <div className="col-sm-6">
            <h1 className="posts-entry-title">{slug.replace('-', ' ')}</h1>
          </div>
        </div>

        {!articles && (
          <>
            <div id="overlayer"></div>
            <div className="loader">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        )}
        <div className="row">
          {articles && articles.length > 0 && articles.map((article, index) => (
            <div className="col-lg-4 mb-4" key={index}>
              <div className="post-entry-alt">
                <a href={`/details/${article.slug}`} className="img-link">
                  <img src={article._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']} alt={article.title.rendered} className="img-fluid" />
                </a>
                <div className="excerpt">
                  

                  <h2><a href={`/details/${article.slug}`}>{article.title.rendered}</a></h2>
                  <div className="post-meta align-items-center text-left clearfix">
                    <figure className="author-figure mb-0 me-3 float-start"><img src={article._embedded['author'][0]['avatar_urls']['48']} alt={article._embedded['author'][0]['name']} className="img-fluid" /></figure>
                    <span className="d-inline-block mt-1">By <a href="/">{article._embedded['author'][0]['name']}</a></span>
                    <span>&nbsp;-&nbsp; {moment(article.date).format('MMM DD, YYYY')}</span>
                  </div>

                  <div dangerouslySetInnerHTML={{__html: article.excerpt.rendered}} />
                  <p><a href={`/details/${article.slug}`} className="read-more">Continue Reading</a></p>
                </div>
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </section>
  )
}

export default PostByCategory
