import axios from "axios"
import { BASE } from "./init"

export const getAllCategories = async () => {
  const response = await axios.get(`${BASE}/categories`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const getCategories = async () => {
  const response = await axios.get(`${BASE}/categories&exclude=1&parent=0`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      const data : any[] = response.data
      await Promise.all(
        data.map(async item => {
          const children = await getCategoryByParent(item.id)
          item.children = children
        })
      )
      
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const getCategoryByParent = async (parent: number) => {
  const response = await axios.get(`${BASE}/categories&exclude=1&parent=${parent}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const getCategoryBySlug = async (slug: string) => {
  const response = await axios.get(`${BASE}/categories&exclude=1&slug=${slug}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data[0]
    } else {
      return null
    }
  }

  return null
}