import ReactDOM from 'react-dom'

import './assets/css/tiny-slider.css'
import './assets/css/aos.css'
import './assets/css/glightbox.min.css'
import './assets/css/style.css'
import './assets/css/flatpickr.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './assets/css/custom.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

import configureStore from './configureStore'
import 'typeface-ibm-plex-sans'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const initialState = window.INITIAL_REDUX_STATE
const store = configureStore(history, initialState)

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
)

reportWebVitals()
