import AOS from 'aos';

const initiateAOS = () => {
  AOS.init({
		duration: 800,
		easing: 'slide',
		once: true
	});
}

const capitalCase = (str: string) => {
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
 
  return arr.join(" ");
}

export {
  initiateAOS,
  capitalCase
}