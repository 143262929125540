import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const RetroySkeleton: React.FC<any> = () => {
  return (
    <div className="row align-items-stretch retro-layout">
      <div className="col-md-4">
        <div className="h-entry mb-30 v-height gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
        <div className="h-entry v-height gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
      </div>
      <div className="col-md-4">
        <div className="h-entry img-5 h-100 gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
      </div>
      <div className="col-md-4">
        <div className="h-entry mb-30 v-height gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
        <div className="h-entry v-height gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
      </div>
    </div>
  )
}

export default RetroySkeleton
