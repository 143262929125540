import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const SidebarSkeleton: React.FC<any> = () => {
  return (
    <li>
      <div style={{
        display: 'flex',
        width: '100%',
      }}>
        <div style={{
          marginRight: 20
        }}>
          <Skeleton width={90} height={90} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
        <div className="text" style={{width: '100%', paddingTop: 5}}>
          <h4><Skeleton width={'100%'} height={20} baseColor='#f2f3f4' highlightColor='#dadcde' count={2} /></h4>
          <div className="post-meta">
            <Skeleton width={130} height={15} baseColor='#f2f3f4' highlightColor='#dadcde' />
          </div>
        </div>
      </div>
    </li>
  )
}

export default SidebarSkeleton
