import axios from "axios"
import { BASE, YARPP } from "./init"

export const paginatePosts = async (category: number | string | null, page: number, per_page: number, search?: string | null) => {
  const categoryString = category ? `&categories=${category}` : ''
  const searchString = search ? `&search=${search}` : ''
  const response = await axios.get(`${BASE}/posts&_embed=true&per_page=${per_page}&page=${page}&orderby=date${categoryString}${searchString}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      const pages = response.headers['x-wp-totalpages']
      const total = response.headers['x-wp-total']
      return {
        data: response.data,
        meta: {
          pages: Number(pages),
          total: Number(total),
        }
      }
    } else {
      return null
    }
  }

  return null
}

export const getPostBySlug = async (slug: string) => {
  const response = await axios.get(`${BASE}/posts&_embed=true&slug=${slug}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data[0]
    } else {
      return null
    }
  }

  return null
}

export const getAttachment = async (id: number) => {
  const response = await axios.get(`${BASE}/media/${id}`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}

export const downloadFile = async (url: string) => {
  const response = await axios.get(url, {
    headers: {
      "Content-Type": 'application/json',
      // "Content-disposition": "attachment; filename=" + "server-dummy.pdf",
    },
    responseType: 'blob',
  })

  console.log(response)

  // if (response.status === 200) {
  //   if (response.data) {
  //     return response.data
  //   } else {
  //     return null
  //   }
  // }

  return null
}

export const getRelatedPosts = async (postId: number) => {
  const response = await axios.get(`${YARPP}/related/${postId}&limit=3&_embed=true`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}
