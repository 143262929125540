import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const ArticleSkeleton: React.FC<any> = () => {
  return (
    <div className="col-md-12 col-lg-8 main-content">
      <div className="post-content-body">
        <div className="row my-4">
          <div className="col-md-12 mb-4">
            <Skeleton width={'100%'} height={400} baseColor='#f2f3f4' highlightColor='#dadcde' />
          </div>
        </div>
        <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' count={8} />
      </div>
      
      <div className="pt-5">
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          Categories:&nbsp;<Skeleton width={200} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
          &nbsp;&nbsp;Tags:&nbsp;<Skeleton width={150} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
      </div>
    </div>
  )
}

export default ArticleSkeleton
