import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const ThirdSkeleton: React.FC<any> = () => {
  return (
    <div className="row align-items-stretch retro-layout-alt">
      <div className="col-md-5 order-md-2">
        <div className="hentry img-1 h-100 gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>
      </div>

      <div className="col-md-7">
        <div className="hentry img-2 v-height mb30 gradient">
          <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
        </div>

        <div className="two-col d-block d-md-flex justify-content-between">
          <div className="hentry v-height img-2 gradient">
            <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
          </div>
          <div className="hentry v-height img-2 ms-auto float-end gradient">
            <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdSkeleton
