import React, { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import moment from 'moment'
import SecondSkeleton from '../Global/SecondSkeleton'

const SecondPost: React.FC<any> = () => {
  const [articles, setArticles] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const response = await paginatePosts(82, 1, 5)
      setArticles(response?.data)
    }

    initiate()
  }, [])

  return (
    <section className="section posts-entry">
      <div className="container">
        <div className="row mb-4">
          <div className="col-sm-6">
            <h1 className="posts-entry-title">Software Development</h1>
          </div>
          <div className="col-sm-6 text-sm-end"><a href="/category/software-development" className="read-more">View All</a></div>
        </div>
        {articles ? (
          <div className="row g-3" data-aos="fade-in">
            <div className="col-md-9">
              <div className="row g-3">
                {articles.map((item, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <div className="col-md-6">
                        <div className="blog-entry">
                          <a href={`/details/${item.slug}`} className="img-link">
                            <img src={item._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']} alt={item.title.rendered} className="img-fluid" />
                          </a>
                          <span className="date">{moment(item.date).format('MMM DD, YYYY')}</span>
                          <h2><a href={`/details/${item.slug}`}>{item.title.rendered}</a></h2>
                          <div dangerouslySetInnerHTML={{__html: item.excerpt.rendered}} />
                          <p><a href={`/details/${item.slug}`} className="btn btn-sm btn-outline-primary">Read More</a></p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="col-md-3">
              <ul className="list-unstyled blog-entry-sm">
                {articles.map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 1 && (
                      <li>
                        <span className="date">{moment(item.date).format('MMM DD, YYYY')}</span>
                        <h3><a href={`/details/${item.slug}`}>{item.title.rendered}</a></h3>
                        <div dangerouslySetInnerHTML={{__html: item.excerpt.rendered}} />
                        <p><a href={`/details/${item.slug}`} className="read-more">Continue Reading</a></p>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <SecondSkeleton />
        )}
      </div>
    </section>
  )
}

export default SecondPost
