import React, { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

const Footer: React.FC<any> = () => {
  const [articles, setArticles] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const response = await paginatePosts(null, 1, 2)
      setArticles(response?.data)
    }

    initiate()
  }, [])
  
  return (
    <footer className="site-footer">
		<div className="container">
			<div className="row">
				<div className="col-lg-4">
					<div className="widget">
						<h3 className="mb-4">About</h3>
						<p>IQRA Technology is a leading provider of IT consultancy, web development, and mobile development services. Our team of experienced professionals is dedicated to delivering innovative solutions that help our clients stay ahead of the curve in the fast-paced world of technology.</p>
					</div>
					<div className="widget">
						<h3>Social</h3>
						<ul className="list-unstyled social">
							<li><a href="mailto:hi@iqra.co.id"><span className="icon-envelope-o"></span></a></li>
							<li><a href="https://www.linkedin.com/company/iqra-tech/"><span className="icon-linkedin"></span></a></li>
						</ul>
					</div>
				</div>
				<div className="col-lg-4 ps-lg-5">
					<div className="widget">
						<h3 className="mb-4">Company</h3>
						<ul className="list-unstyled float-start links">
							<li><a href="https://iqra.co.id">About us</a></li>
							<li><a href="/">Terms</a></li>
							<li><a href="/">Privacy</a></li>
						</ul>
						<ul className="list-unstyled float-start links">
							<li><a href="/">Partners</a></li>
							<li><a href="/">Blog</a></li>
							<li><a href="/">FAQ</a></li>
						</ul>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="widget">
						<h3 className="mb-4">Recent Post Entry</h3>
						<div className="post-entry-footer">
							<ul>
                {articles ? articles.map((article, index) => (
                  <li key={index}>
                    <a href={`/details/${article.slug}`}>
                      <img src={article._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url']} alt={article.title.rendered} className="me-4 rounded" />
                      <div className="text">
                        <h4>{article.title.rendered}</h4>
                        <div className="post-meta">
                          <span className="mr-2">{moment(article.date).format('MMM DD, YYYY')} </span>
                        </div>
                      </div>
                    </a>
                  </li>
                )) : [0, 1].map(item => (
                  <li key={item}>
                    <div className='a-href'>
                      <div className="me-4 rounded ske-img">
                        <Skeleton width={'100%'} height={50} baseColor='#1C6758' />
                      </div>
                      <div className="text" style={{width: '75%'}}>
                        <h4><Skeleton width={'100%'} height={'100%'} baseColor='#1C6758' count={2} /></h4>
                        <div className="post-meta">
                          <span className="mr-2"><Skeleton width={120} height={'100%'} baseColor='#1C6758' /></span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-12 text-center">
            <p>Copyright &copy; {moment(new Date()).format('YYYY')} <a href="https://iqra.co.id">iqra.co.id</a>. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
