import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const SecondSkeleton: React.FC<any> = () => {
  return (
    <div className="row g-3">
      <div className="col-md-9">
        <div className="row g-3">
          {[0, 1].map((item) => (
            <div className="col-md-6" key={item}>
              <div className="blog-entry">
                <div className="img-link" style={{width: '100%'}}>
                  <Skeleton width={'100%'} height={250} baseColor='#f2f3f4' highlightColor='#dadcde' />
                </div>
                <span className="date"><Skeleton width={150} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></span>
                <h2><Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></h2>
                <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' count={4} />
                <Skeleton width={130} height={40} baseColor='#f2f3f4' highlightColor='#dadcde' />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-3">
        <ul className="list-unstyled blog-entry-sm">
          {[2, 3, 4].map((item) => (
            <li key={item}>
              <span className="date"><Skeleton width={150} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></span>
              <h3><Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></h3>
              <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' count={4} />
              <Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SecondSkeleton
