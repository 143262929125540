import axios from "axios"
import { BASE } from "./init"

export const getAllTags = async () => {
  const response = await axios.get(`${BASE}/tags`, {
    headers: {
      "Content-Type": 'application/json',
      "Accept": "application/json",
    },
  })

  if (response.status === 200) {
    if (response.data) {
      return response.data
    } else {
      return null
    }
  }

  return null
}
