import { useEffect, useState } from 'react'
import { enquireScreen } from 'enquire-js'
import Header from '../components/Blog/Header'
import Loader from '../components/Blog/Loader'
import Footer from '../components/Blog/Footer'
import PostBySlug from '../components/Blog/PostBySlug'

let isThisMobile : boolean
enquireScreen((b : boolean) => {
  isThisMobile = b
})

const SinglePost = () => {
  const [isMobile, setIsMobile] = useState(isThisMobile)
  const [show, isShown] = useState(false)
  const [headerClassName, setHeaderClassName] = useState('')

  useEffect(() => {
    const handleScroll = (headerClassName: string) => {
      if (headerClassName !== 'menu-with-bg' && window.pageYOffset >= 150) {
          setHeaderClassName('menu-with-bg');
      } else if (headerClassName === 'menu-with-bg' && window.pageYOffset < 150) {
          setHeaderClassName('');
      }
    }

    window.onscroll = () => handleScroll(headerClassName);

    enquireScreen((b : boolean) => {
      setIsMobile(!!b)
    })
    
    if (window.location.port) {
      setTimeout(() => {
        isShown(true)
      }, 100)
    } else {
      isShown(true)
    }
  }, [headerClassName, isMobile, show])

  const children = [
    <Header
      isMobile={isMobile}
      key="Header"
    />,
    <PostBySlug
      isMobile={isMobile}
      key="PostBySlug"
    />,
    <Footer
      isMobile={isMobile}
      key="Footer"
    />,
  ]

  return (
    <div
      className="templates-wrapper"
    >
      {!show ? <Loader /> : children}
    </div>
  )
}

export default SinglePost
