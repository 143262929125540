import React, { useEffect, useState } from 'react'
import { getPostBySlug, getRelatedPosts } from '../../services/posts'
import moment from 'moment'
import {Helmet} from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getAllCategories } from '../../services/categories';
import Skeleton from 'react-loading-skeleton';
import HeaderSkeleton from '../Global/HeaderSkeleton';
import ArticleSkeleton from '../Global/ArticleSkeleton';

const PostBySlug: React.FC<any> = () => {
  let { slug } = useParams<any>();
  const [article, setArticle] = useState<any>()
  const [relatedPosts, setRelatedPosts] = useState<any[]>()
  const [categories, setCategories] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const data = await getPostBySlug(slug)
      setArticle(data)

      const related = await getRelatedPosts(data.id)
      setRelatedPosts(related)

      const categories = await getAllCategories()
      setCategories(categories)
    }

    initiate()
  }, [slug])
  console.log(article)
  return (
    <>
      {article && (
        <Helmet>
          <title>{article?.title.rendered}</title>
          <meta name="description" content={article?.title.rendered} />
          <meta name="keywords" content={article?._embedded['wp:term'][1].map((item: any) => (
            item.name
          ))} />
        </Helmet>
      )}
      
      <div className="site-cover site-cover-sm same-height overlay single-page" style={{
        backgroundImage: `url("${article?._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`,
        backgroundPosition: "center"
      }}>
        <div className="container">
          <div className="row same-height justify-content-center">
            <div className="col-md-6">
              {!article && <HeaderSkeleton />}
              {article && (
                <div className="post-entry text-center">
                  <h1 className="mb-4">{article?.title.rendered}</h1>
                  <div className="post-meta align-items-center text-center">
                    <figure className="author-figure mb-0 me-3 d-inline-block"><img src={article?._embedded['author'][0]['avatar_urls']['48']} alt={article?._embedded['author'][0]['name']} className="img-fluid" /></figure>
                    <span className="d-inline-block mt-1">By {article?._embedded['author'][0]['name']}</span>
                    <span>&nbsp;-&nbsp; {moment(article?.date).format('MMMM DD, YYYY')}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row blog-entries element-animate">
            {article && (
              <div className="col-md-12 col-lg-8 main-content">
                <div className="post-content-body">
                  {!article.categories.includes(110) && (
                    <div className="row my-4">
                      <div className="col-md-12 mb-4">
                        <img src={article?._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']} alt={article?.title.rendered} className="img-fluid with-height rounded" />
                      </div>
                    </div>
                  )}
                  
                  <div dangerouslySetInnerHTML={{__html: article?.content.rendered}} />
                </div>
                
                <div className="pt-5">
                  <p>
                    Categories: {article?._embedded['wp:term'][0].map((item: any, index: number) => <a href={`/category/${item.slug}`} key={`category_${index}`}>{item.name}</a>)}
                    &nbsp;&nbsp;Tags: {article?._embedded['wp:term'][1].map((item: any, index: number) => (
                      <React.Fragment key={`tag_${index}`}>
                        {index === article?._embedded['wp:term'][1].length - 1 ? <a href={`/category/${item.slug}`}>#{item.name}</a> : <><a href={`/category/${item.slug}`}>#{item.name}</a><span>,</span>&nbsp;</>}
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )}
            {!article && <ArticleSkeleton />}

            <div className="col-md-12 col-lg-4 sidebar">
              <div className="sidebar-box">
                <div className="bio text-center">
                  <img src={article?._embedded['author'][0]['avatar_urls']['96']} alt={article?._embedded['author'][0]['name']} className="img-fluid mb-3" />
                  <div className="bio-body">
                    <h2>{article?._embedded['author'][0]['name']}</h2>
                    <p className="mb-4">{article?._embedded['author'][0]['description']}</p>

                    <div className="widget">
                      <ul className="list-unstyled social">
                        <li><a rel='noreferrer' href="https://www.instagram.com/yashdiq/" target='_blank'><span className="icon-instagram"></span></a></li>
                        <li><a rel='noreferrer' href="https://www.linkedin.com/in/yashdiq/" target='_blank'><span className="icon-linkedin"></span></a></li>
                        <li><a rel='noreferrer' href="https://youtube.com/@iqra.studio" target='_blank'><span className="icon-youtube-play"></span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="sidebar-box">
                <h3 className="heading">Popular Posts</h3>
                <div className="post-entry-sidebar">
                  <ul>
                    {relatedPosts?.map(item => (
                      <li key={`related_${item.id}`}>
                        <a href={`/details/${item.slug}`}>
                          <img src={item._embedded['wp:featuredmedia'][0]['media_details']['sizes']['thumbnail']['source_url']} alt={item.title.rendered} className="me-4 rounded" />
                          <div className="text">
                            <h4>{item.title.rendered}</h4>
                            <div className="post-meta">
                              <span className="mr-2">{moment(item.date).format('MMM DD, YYYY')} </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="sidebar-box">
                <h3 className="heading">Categories</h3>
                <ul className="categories">
                  {categories?.map(item => (
                    <li key={`category_${item.id}`}><a href={`/category/${item.slug}`}>{item.name} <span>({item.count})</span></a></li>
                  ))}
                  
                  {!categories && <li><Skeleton width={'100%'} height={15} baseColor='#f2f3f4' highlightColor='#dadcde' /></li>}
                </ul>
              </div>

              <div className="sidebar-box">
                <div className="post-entry-sidebar">
                  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3289866747817557"
                    crossOrigin="anonymous"></script>
                  <ins className="adsbygoogle"
                    style={{ display: 'block'}}
                    data-ad-client="ca-pub-3289866747817557"
                    data-ad-slot="2089833839"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                  <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                  </script>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PostBySlug
