import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Store } from 'redux'
import { History } from 'history'
import { ApplicationState } from './stores'
import Home from './screens/Home'
import PostCategory from './screens/PostCategory'
import SinglePost from './screens/SinglePost'
import SearchPost from './screens/SearchPost'

interface IAppProps {
  store: Store<ApplicationState>
  history: History
}

const App: React.FC<IAppProps> = ({ store, history }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_PATH}>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/category/:slug' component={PostCategory} />
            <Route path='/details/:slug' component={SinglePost} />
            <Route path='/search' component={SearchPost} />
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
