export const createMobileMenu = (cloneNavRef: any, siteMobileMenuBodyRef: any) => {
  const jsCloneNavs = cloneNavRef.current
  const siteMobileMenuBody = siteMobileMenuBodyRef.current

  const navCloned = jsCloneNavs.cloneNode(true)
  navCloned.setAttribute('class', 'site-nav-wrap')
  siteMobileMenuBody.appendChild(navCloned);
  
  setTimeout(() => {
    let hasChildrens = document.querySelector('.site-mobile-menu')?.querySelectorAll(' .has-children');

    let counter = 0;
    hasChildrens?.forEach( hasChild => {
      
      let refEl = hasChild.querySelector('a');

      let newElSpan = document.createElement('span');
      newElSpan.setAttribute('class', 'arrow-collapse collapsed');

      // prepend equivalent to jquery
      hasChild.insertBefore(newElSpan, refEl);

      let arrowCollapse = hasChild.querySelector('.arrow-collapse');
      arrowCollapse?.setAttribute('data-bs-toggle', 'collapse');
      arrowCollapse?.setAttribute('data-bs-target', '#collapseItem' + counter);

      let dropdown = hasChild.querySelector('.dropdown');
      dropdown?.setAttribute('class', 'collapse');
      dropdown?.setAttribute('id', 'collapseItem' + counter);

      counter++;
    });
  }, 1000);

  let menuToggle = document.querySelectorAll(".js-menu-toggle");
  let mTog: Element;
  menuToggle.forEach(mtoggle => {
    mTog = mtoggle;
    mtoggle.addEventListener("click", (e) => {
      if ( document.body.classList.contains('offcanvas-menu') ) {
        document.body.classList.remove('offcanvas-menu');
        mtoggle.classList.remove('active');
        mTog.classList.remove('active');
      } else {
        document.body.classList.add('offcanvas-menu');
        mtoggle.classList.add('active');
        mTog.classList.add('active');
      }
    });
  })

  let specifiedElement = document.querySelector(".site-mobile-menu");
  let mt: any, mtoggleTemp: any;
  document.addEventListener('click', (event: any) => {
    let isClickInside = specifiedElement?.contains(event.target);
    menuToggle.forEach(mtoggle => {
      mtoggleTemp = mtoggle
      mt = mtoggle.contains(event.target);
    })

    if (!isClickInside && !mt) {
      if ( document.body.classList.contains('offcanvas-menu') ) {
        document.body.classList.remove('offcanvas-menu');
        mtoggleTemp.classList.remove('active');
      }
    }

  });
}