import React, { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import moment from 'moment'
import RetroySkeleton from '../Global/RetroySkeleton'

const RetroyPost: React.FC<any> = () => {
  const [articles, setArticles] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const response = await paginatePosts(null, 1, 5)
      setArticles(response?.data)
    }

    initiate()
  }, [])

  return (
    <section className="section bg-light">
      <div className="container">
        {articles ? (
          <div className="row align-items-stretch retro-layout" data-aos="fade-in">
            <div className="col-md-4">
              <a href={`/details/${articles[0].slug}`} className="h-entry mb-30 v-height gradient">
                <div className="featured-img" style={{
                  backgroundImage: `url("${articles[0]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                }}></div>

                <div className="text">
                  <span className="date">{moment(articles[0].date).format('MMM DD, YYYY')}</span>
                  <h2>{articles[0].title.rendered}</h2>
                </div>
              </a>
              <a href={`/details/${articles[1].slug}`} className="h-entry v-height gradient">
                <div className="featured-img" style={{
                  backgroundImage: `url("${articles[1]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                }}></div>

                <div className="text">
                  <span className="date">{moment(articles[1].date).format('MMM DD, YYYY')}</span>
                  <h2>{articles[1].title.rendered}</h2>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a href={`/details/${articles[2].slug}`} className="h-entry img-5 h-100 gradient">
                <div className="featured-img" style={{
                  backgroundImage: `url("${articles[2]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                }}></div>

                <div className="text">
                  <span className="date">{moment(articles[2].date).format('MMM DD, YYYY')}</span>
                  <h2>{articles[2].title.rendered}</h2>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <a href={`/details/${articles[3].slug}`} className="h-entry mb-30 v-height gradient">
                <div className="featured-img" style={{
                  backgroundImage: `url("${articles[3]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                }}></div>

                <div className="text">
                  <span className="date">{moment(articles[3].date).format('MMM DD, YYYY')}</span>
                  <h2>{articles[3].title.rendered}</h2>
                </div>
              </a>
              <a href={`/details/${articles[4].slug}`} className="h-entry v-height gradient">
                <div className="featured-img" style={{
                  backgroundImage: `url("${articles[4]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                }}></div>

                <div className="text">
                  <span className="date">{moment(articles[4].date).format('MMM DD, YYYY')}</span>
                  <h2>{articles[4].title.rendered}</h2>
                </div>
              </a>
            </div>
          </div>
        ) : (
          <RetroySkeleton />
        )}
      </div>
    </section>
  )
}

export default RetroyPost
