import React, { useEffect, useState } from 'react'
import { paginatePosts } from '../../services/posts'
import moment from 'moment'
import ThirdSkeleton from '../Global/ThirdSkeleton'

const ThirdPost: React.FC<any> = () => {
  const [articles, setArticles] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const response = await paginatePosts(81, 1, 4)
      setArticles(response?.data)
    }

    initiate()
  }, [])

  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row mb-4">
          <div className="col-sm-6">
            <h1 className="posts-entry-title">Technology</h1>
          </div>
          <div className="col-sm-6 text-sm-end"><a href="/category/technology" className="read-more">View All</a></div>
        </div>
        
        {articles ? (
          <div className="row align-items-stretch retro-layout-alt" data-aos="fade-in">
            <div className="col-md-5 order-md-2">
              {articles[1] && (
                <a href={`/details/${articles[1].slug}`} className="hentry img-1 h-100 gradient">
                  <div className="featured-img" style={{
                    backgroundImage: `url("${articles[1]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                  }}></div>
                  <div className="text">
                    <span>{moment(articles[1].date).format('MMMM DD, YYYY')}</span>
                    <h2>{articles[1].title.rendered}</h2>
                  </div>
                </a>
              )}
            </div>

            <div className="col-md-7">
              {articles[0] && (
                <a href={`/details/${articles[0].slug}`} className="hentry img-2 v-height mb30 gradient">
                  <div className="featured-img" style={{
                    backgroundImage: `url("${articles[0]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                  }}></div>
                  <div className="text text-sm">
                    <span>{moment(articles[0].date).format('MMMM DD, YYYY')}</span>
                    <h2>{articles[0].title.rendered}</h2>
                  </div>
                </a>
              )}

              <div className="two-col d-block d-md-flex justify-content-between">
                {articles[2] && (
                  <a href={`/details/${articles[2].slug}`} className="hentry v-height img-2 gradient">
                    <div className="featured-img" style={{
                      backgroundImage: `url("${articles[2]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                    }}></div>
                    <div className="text text-sm">
                      <span>{moment(articles[2].date).format('MMMM DD, YYYY')}</span>
                      <h2>{articles[2].title.rendered}</h2>
                    </div>
                  </a>
                )}
                {articles[3] && (
                  <a href={`/details/${articles[3].slug}`} className="hentry v-height img-2 ms-auto float-end gradient">
                    <div className="featured-img" style={{
                      backgroundImage: `url("${articles[3]._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}")`
                    }}></div>
                    <div className="text text-sm">
                      <span>{moment(articles[3].date).format('MMMM DD, YYYY')}</span>
                      <h2>{articles[3].title.rendered}</h2>
                    </div>
                  </a>
                )}
              </div>  

            </div>
          </div>
        ) : (
          <ThirdSkeleton />
        )}
      </div>
    </section>
  )
}

export default ThirdPost
