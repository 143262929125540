/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getRelatedPosts, paginatePosts } from '../../services/posts'
import moment from 'moment'
import { useSearchParams } from '../../hooks/useSearchParams';
import ListArticleSkeleton from '../Global/ListArticleSkeleton';
import SidebarSkeleton from '../Global/SidebarSkeleton';
import { getAllCategories } from '../../services/categories';
import Skeleton from 'react-loading-skeleton';
import { getAllTags } from '../../services/tags';

const PostBySearch: React.FC<any> = () => {
  const params = useSearchParams()
  const q = params.get('q')
  
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState<number>(1)
  const [articles, setArticles] = useState<any[]>()
  const [relatedPosts, setRelatedPosts] = useState<any[]>()
  const [categories, setCategories] = useState<any[]>()
  const [tags, setTags] = useState<any[]>()

  useEffect(() => {
    const initiate = async () => {
      const response = await paginatePosts(null, page, 10, q)

      const data = response?.data
      setArticles(data)
      setPages(response?.meta.pages || 1)

      const related = await getRelatedPosts(data?.id || 37)
      setRelatedPosts(related)

      const categories = await getAllCategories()
      setCategories(categories)

      const tags = await getAllTags()
      setTags(tags)
    }

    initiate()
  }, [page, q])

  const getPagination = () => {
    const elements = []
    for (let index = 0; index < pages; index++) {
      elements.push(
        <span key={index} className={page === index+1 ? 'active' : 'no-active'} onClick={() => setPage(index+1)}>{index+1}</span>
      )
    }
    return elements
  }

  return (
    <section className="section search-result-wrap">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading">Search: '{q}'</div>
          </div>
        </div>
        <div className="row posts-entry">
          <div className="col-lg-8">
            {articles && articles.length > 0 && articles.map((article, index) => (
              <div className="blog-entry d-flex blog-entry-search-item" key={index}>
                <a href={`/details/${article.slug}`} className="img-link me-4">
                  <img
                    src={article._embedded['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']}
                    alt={article.title.rendered} className="img-fluid"
                    style={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                    }}
                  />
                </a>
                <div style={{width: '100%'}}>
                  <span className="date">{moment(article.date).format('MMM DD, YYYY')}</span>
                  <h2><a href={`/details/${article.slug}`}>{article.title.rendered}</a></h2>
                  <div dangerouslySetInnerHTML={{__html: article.excerpt.rendered}} />
                  <p><a href={`/details/${article.slug}`} className="btn btn-sm btn-outline-primary">Read More</a></p>
                </div>
              </div>
            ))}
            
            {!articles && <ListArticleSkeleton />}

            <div className="row text-start pt-5 border-top">
              <div className="col-md-12">
                <div className="custom-pagination">
                  {pages && getPagination()}
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4 sidebar">
            <div className="sidebar-box">
              <h3 className="heading">Popular Posts</h3>
              <div className="post-entry-sidebar">
                <ul>
                  {!relatedPosts && <SidebarSkeleton />}
                  {relatedPosts?.map(item => (
                    <li key={`related_${item.id}`}>
                      <a href={`/details/${item.slug}`}>
                        <img src={item._embedded['wp:featuredmedia'][0]['media_details']['sizes']['thumbnail']['source_url']} alt={item.title.rendered} className="me-4 rounded" />
                        <div className="text">
                          <h4>{item.title.rendered}</h4>
                          <div className="post-meta">
                            <span className="mr-2">{moment(item.date).format('MMM DD, YYYY')} </span>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="sidebar-box">
              <h3 className="heading">Categories</h3>
              <ul className="categories">
                {categories?.map(item => (
                  <li key={`category_${item.id}`}><a href={`/category/${item.slug}`}>{item.name} <span>({item.count})</span></a></li>
                ))}
                
                {!categories && <li><Skeleton width={'100%'} height={15} baseColor='#f2f3f4' highlightColor='#dadcde' /></li>}
              </ul>
            </div>
            
            <div className="sidebar-box">
              <h3 className="heading">Tags</h3>
              <ul className="tags">
                {tags?.map(item => (
                  <li key={`tag_${item.id}`}><a href={`/category/${item.slug}`}>{item.name}</a></li>
                ))}

                {!tags && <li><Skeleton width={100} height={28} baseColor='#f2f3f4' highlightColor='#dadcde' /></li>}
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default PostBySearch
