import React, {  } from 'react'
import Skeleton from 'react-loading-skeleton'

const HeaderSkeleton: React.FC<any> = () => {
  return (
    <div className="post-entry text-center">
      <h1 className="mb-4"><Skeleton width={'100%'} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' count={2} /></h1>
      <div className="post-meta align-items-center text-center" style={{ display: 'flex', justifyContent: 'center'}}>
        <figure className="author-figure mb-0 me-3 d-inline-block"><Skeleton width={30} height={30} baseColor='#f2f3f4' highlightColor='#dadcde' borderRadius={15} /></figure>
        <span>By <Skeleton width={100} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></span>
        <span>&nbsp;-&nbsp; <Skeleton width={100} height={'100%'} baseColor='#f2f3f4' highlightColor='#dadcde' /></span>
      </div>
    </div>
  )
}

export default HeaderSkeleton
